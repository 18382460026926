import BaseAPI from '../base'

function prepare(code) {
    return BaseAPI.sendPost('/hotels/prepare', {
        code
    }); 
}

function create(data) {
    return BaseAPI.sendPost(`/hotels/create`, data);
}

export default {
    prepare,
    create
}