<template>
  <div class="room-order-bill">
    <div class="header">
      <div class="order-id">
        <h3>ROOMS</h3>
        <span>
          id:
          <h3 class="d-inline">{{ room_order.local_id }}</h3>
        </span>
      </div>
      <ClientName :clientName="room_order.client_name" :systemColor="'hotels'" />
      <div class="order-details">
        <div class="p-0">
          <div>
            <img src="/icons/mobile-white.svg" />
            <p class="float-right">{{ room_order.client_phone }}</p>
          </div>
          <div v-if="room_order.target_date">
            <img src="/icons/time-white.svg" />
            <p class="float-right">{{ formattedTime }}</p>
          </div>
          <div>
            <img 
                :src="room_order.pay_method == 'cash' ? '/icons/pay-by-cash-white-color-small.svg' : '/icons/header/card-white.svg'" />
            <p class="float-right">{{ $t(`pay_methods.${room_order.pay_method}.short`) }}</p>
          </div>

          <!-- <div>
            <img src="/icons/mobile-white.svg" />
            <p class="float-right">{{ room_order.client_phone }}</p>
          </div> -->

          <!-- <div v-if="preorder.takeaway_date">
          <img
            :src="!isPaid ? '/icons/time-white.svg' : '/icons/header/time.svg'"
          />
           <p class="order-info">{{ $t('preorder_bill.takeaway') }}</p> -->
          <!-- <p class="float-right">{{ takeawayDateTimeString }}</p> -->
        </div>
      </div>
    </div>
    <CardList 
      :list="room_order.list" 
      status="executed" 
      :billTotal="room_order.total"
      :virtual="[]" />

    <div class="total-block">
      <h4 class="total">{{ $t('list.total')}}</h4>
      <span class="amount">{{room_order.total}}{{currency.symbol}}</span>
    </div>

    <LongButton
    class="btn-transparent"
      @click="onSave"
    >{{ $t('bill.download_check') }}</LongButton>
  </div>
</template>

<script>
import ClientName from "@/components/order-details/ClientName.vue";
import CardList from "@/components/card/CardList.vue"

import LongButton from "@/components/LongButton.vue"

import moment from 'moment'

export default {
  props: {
    room_order: Object,
  },
  components: {
    ClientName,
    CardList,
    LongButton
  },
  methods: {
      onSave() {
        this.$emit('save');
      }
  },
  computed: {
      formattedTime() {
          if (!this.room_order.target_date) return '';
          return moment(this.room_order.target_date).format('HH:mm');
      },
      currency() {
          return this.$store.state.currency.current;
      }
  }
};
</script>

<style scoped>
.room-order-bill {
  width: 90vw;
  margin: 0 auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #ededed;
  border-radius: 5vh;
  padding-bottom: 3.7vh;
}

 

.order-id span {
  width: 100%;
  text-align: right;
  font-size: 4vw;
}

.header {
  border-radius: 2.5vh 2.5vh 0 0;
  background: #1d1d1b;
  color: #ededed;
}

.order-details {
  padding: 1.2vh 5.5vw 2.5vh;

  padding-top: 0;
}

.order-details div {
  padding-top: 1.9vh;
}

p.float-right {
  line-height: 0.8;
  display: inline-block;
  padding-top: 0.6vh;
}

.total-block {
  padding: 0 5.4vw;
  margin-top: 2.5vh;
}

.total {
  font-weight: bold;
  display: inline-block;
  margin: 0 !important;
  font-size: 6.5vw;
  text-align: initial;
  padding-top: 0.9vh;
  line-height: 0.8;
}

span.amount {
  float: right;
  margin-top: 0vh;
  line-height: 0.8;
  font-size: 9.6vw;
  font-weight: 500;
}

.btn-transparent {
  box-shadow: none;
  background: transparent;
  border:1px solid #1d1d1d;
  color: #1d1d1d;
}
</style>