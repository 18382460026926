<template>
  <div class="hotel-room-wrapper">
    <OrderLoader v-if="loading && !error" />
    <LoadError :error="error" v-if="error" />

    <div class="hotel-room-content" v-if="!error && !loading">
      <Navbar
        :cafe="cafe"
        :target="target"
        :showArrowBack="showArrowBack"
        @go-back="navigateBack"
      />

      <OrderMenu
        ref="orderMenu"
        :cafe="cafe"
        :menu="currentMenu"
        :menu_index="currentMenuIndex"
        :tagged_menus="menus"
        :cart="cart"
        :cartLocked="false"
        @switchView="onSwitchView"
        @switchMenu="switchToMenu"
        @menuViewChange="(v) => (menu_view = v)"
        :target="target"
        v-if="currentView === 'menu'"
        v-show="!order_modal_shown"
      />

      <RoomCartScreen
        :cafe="cafe"
        :target="target"
        @switchView="onSwitchView"
        @created="onOrderCreated"
        v-if="currentView == 'cart' && !room_order"
      >
      </RoomCartScreen>

      <RoomCheckScreen
        :cafe="cafe"
        :room_order="room_order"
        v-if="room_order"
      />

      <OrderModalTemplate
        titleMode="ROOMS"
        modeBgColor="#ededed"
        :modePicture="'/hero-pics/choose-system.svg'"
        :motd="cafe.hotels.motd"
        @close="order_modal_shown = false"
        :cafe="cafe"
        v-if="order_modal_shown"
      />
    </div>
  </div>
</template>

<script>
import OrderLoader from "@/components/OrderLoader.vue";
import LoadError from "@/components/LoadError.vue";

import OrderMenu from "@/components/OrderMenu.vue";
import Navbar from "@/components/Navbar.vue";

import RoomCartScreen from "@/components/hotels/RoomCartScreen.vue";
import RoomCheckScreen from "@/components/hotels/RoomCheckScreen.vue";

import HotelsAPI from "@/api/hotels";
import AnalyticsAPI from "@/api/analytics";

import OrderModalTemplate from "@/components/modals/OrderModalTemplate.vue";

export default {
  components: {
    OrderLoader,
    LoadError,
    OrderMenu,
    Navbar,
    RoomCartScreen,
    RoomCheckScreen,
    OrderModalTemplate,
  },
  data() {
    return {
      loading: true,
      error: null,

      cafe: {},
      menus: [],
      target: {},

      order_modal_shown: true,

      room_order: null,

      menu_view: "categories",

      currentMenuIndex: 0,
      currentView: "menu",
    };
  },
  methods: {
    init() {
      const code = this.$route.params.code;

      HotelsAPI.prepare(code)
        .then((data) => {
          this.cafe = data.cafe;
          this.menus = data.menus;
          this.target = data.target;

          this.$store.dispatch("cart/init", {
            cafe_id: this.cafe._id,
            type: "hotels",
          });

          this.$store.dispatch("currency/fetch", this.cafe.currency_id);
          this.$store.dispatch("worldwide/init", data.country);

          // this.$store.commit("menu/setMenu", this.menus[0]);
          this.$store.commit("menu/setMenus", this.menus);
          this.$store.dispatch("menu/buildAllTranslations");
          this.$store.commit("menu/addBuiltinTranslations", this.$i18n.locale);

          this.$store
            .dispatch("clients/init")
            .then((ok) => {
              this.$store.dispatch("clients/fetchBalance", {
                cafe_id: this.cafe._id,
              });
            })
            .finally(() => {
              AnalyticsAPI.registerView({
                cafe_id: this.cafe._id,
                mode: "rooms",
                client_id: this.$store.state.clients.client_id,
                platform: "web",
                from_marketplace: false,
                referer: document.referrer
              }).catch((error) => {
                console.error(`Analytics registerView() failed: ${error}`);
              });
            });

          // this.order_modal_shown = !!this.cafe.hotels.motd;
          this.loading = false;
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
        });
    },
    onSwitchView(view) {
      this.currentView = view;
      this.menu_view = 'categories';
    },
    onOrderCreated(order) {
      this.room_order = order;
    },
    switchToMenu(index) {
      this.currentMenuIndex = index;

      // this.$store.commit("menu/setMenu", this.menus[this.currentMenuIndex]);
      // this.$store.commit("menu/buildDictionaries");
    },
    navigateBack() {
      if (this.currentView == "cart") {
        this.currentView = "menu";
      } else {
        this.$refs.orderMenu.onBackArrowPressed();
      }
    },
  },
  computed: {
    cart() {
      return this.$store.state.cart.items;
    },
    currentMenu() {
      return this.menus[this.currentMenuIndex];
    },
    showArrowBack() {
      if (this.room_order) return false;

      if (this.currentView == "menu") return this.menu_view != "categories";

      return true;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
</style>