<template>
  <div class="room-check-screen">
    <RoomOrderBill @save="saveBill" :room_order="room_order" />

    <div class="chequeue-comment" v-if="room_order.comment">
      <h4>{{ $t("cart.comment") }}</h4>
      <p v-thematic class="info">
        {{ room_order.comment }}
      </p>
    </div>

    <FeedbackButton @click="show_feedback = true" v-if="!feedback_sent" />

    <CafeFeedback
      :order_id="room_order._id"
      :mode="'hotels'"
      @cancel="show_feedback = false"
      @success="
        feedback_sent = true;
        show_feedback = false;
      "
      v-if="show_feedback"
    />
  </div>
</template>

<script>
import RoomOrderBill from "@/components/hotels/RoomOrderBill.vue";

import FeedbackButton from "@/components/FeedbackButton.vue";
import CafeFeedback from "@/components/CafeFeedback.vue";

import BillSaver from "@/services/billsaver";

export default {
  props: {
    room_order: Object,
    cafe: Object,
  },
  data() {
    return {
      show_feedback: false,
      feedback_sent: false,
    };
  },
  methods: {
    saveBill() {
      const locale = this.$i18n.messages[this.$i18n.locale].download_bill;
      const currency = this.$store.state.currency.current.code;
      BillSaver.generateAndSaveBill(
        this.cafe.name,
        this.room_order,
        locale,
        currency
      );
    },
  },
  components: {
    RoomOrderBill,
    FeedbackButton,
    CafeFeedback,
  },
};
</script>

<style scoped>
.room-check-screen {
  padding-top: 9.5vh;
  margin-bottom: 15vh;
  min-height: 85vh;
}

.chequeue-comment {
  background: transparent;
  padding: 2vh;
  border: 1px solid #908f8d;
  border-radius: 1.2vh;
  width: 90%;
  margin: 2.5vh auto 0;
}

.chequeue-comment h4 {
  color: #a4a3a1;
  font-size: 2.3vh;
  margin: 0;
}

.chequeue-comment p.info {
  font-size: 1.8vh;
  color: #1d1d1d;
  margin: 0;
}

.chequeue-comment p.info.dark {
  color: #ededed;
}
</style>