<template>
  <div class="room-order-details">
    <ClientNameField :valid="validation.name" v-model="details.name" />
    <ClientPhoneField @click="phone_confirm_ui = true" :valid="validation.phone" v-model="details.phone" />
    <!-- <ChooseTimeField
      :dates="availableTimes"
      :time_only="true"
      :allow_asap="true"
      :valid="validation.time"
      v-model="details.target_date"
    /> -->
    <PayMethods
      v-model="details.pay_method"
      :total="total"
      :validation="validation.pay_method"
      :available_methods="cafe.hotels.pay_methods"
    />

    <CommentField class="comment-field" v-model="details.comment" />

    <div @click="onConfirm" class="order-confirm-button">
      <h4>{{ $t("order_buttons.send") }}</h4>
      <small class="pay"
        >{{ totalDishesInCart }} ({{ totalCartPrice }}
        {{ currency.code }})</small
      >
    </div>

    <PhoneConfirmModal
      @confirmed="onPhoneConfirmed"
      @resend="askPhoneConfirmation"
      @cancel="confirming_phone = false"
      v-if="confirming_phone"
      :token="confirm_phone_token"
    >
    </PhoneConfirmModal>

    <PhoneConfirmationUI 
      :initial_phone="details.phone"
      @close="phone_confirm_ui = false"
      @confirm="onNewPhoneConfirmed"
      v-if="phone_confirm_ui"/>
  </div>
</template>

<script>
import PayMethods from "@/components/PayMethods.vue";

import CommentField from "@/components/order-details/CommentField.vue";
import ClientNameField from "@/components/order-details/ClientNameField.vue";
import ClientPhoneField from "@/components/order-details/ClientPhoneField.vue";
import ChooseTimeField from "@/components/order-details/ChooseTimeField.vue";

import PhoneConfirmModal from "@/components/modals/PhoneConfirmModal.vue";

import PhoneConfirmationUI from "@/components/modals/PhoneConfirmationUI.vue"

import ScheduleService from "@/services/schedule";

import moment from "moment";

import SecurityAPI from "@/api/security";

export default {
  components: {
    PayMethods,
    CommentField,
    ClientPhoneField,
    ClientNameField,
    ChooseTimeField,
    PhoneConfirmModal,
    PhoneConfirmationUI
  },
  data() {
    return {
      details: {
        name: null,
        phone: null,
        target_date: null,
        pay_method: null,
        comment: null,
      },
      validation: {
        name: true,
        time: true,
        phone: true,
        pay_method: true,
      },

      phone_confirm_ui: false,

      confirming_phone: false,
      confirm_phone_token: null,
    };
  },
  props: {
    cafe: Object,
  },
  watch: {
    'details.name'() {
      if (this.details.name && this.details.name.length >= 2) {
        this.validation.name = true;
      }
    },
    'details.phone'() {
      if (this.details.phone) this.validation.phone = true;
    },
    'details.pay_method'() {
      if (this.details.pay_method) {
        this.validation.pay_method = true;
      }
    }
  },
  computed: {
    total() {
      return this.$store.getters["cart/total"];
    },
    currency() {
      return this.$store.state.currency.current;
    },
    totalDishesInCart() {
      return this.$tc(
        "order_buttons.dishes",
        this.$store.state.cart.items.length
      );
    },
    totalCartPrice() {
      return this.$store.getters["cart/total"];
    },
    availableTimes() {
      // let times = [];

      // let ptr = moment()
      //   .add(15, "minutes")
      //   .minutes(moment().minutes() + (10 - (moment().minutes() % 10)));

      // let end = moment().add(12, "hours");

      // while (ptr.isBefore(end)) {
      //   times.push(ptr.toDate());

      //   ptr = ptr.add(10, "minutes");
      // }

      // return times;

      // if (this.cafeClosed) {
      //   let helper = new ScheduleService.ScheduleHelper(
      //     this.schedule,
      //     now.valueOf()
      //   );

      //   let loop_limit = 100;

      //   while (!helper.isOpen() && loop_limit > 0) {
      //     now = moment(now).add(1, "hour");

      //     helper = new ScheduleService.ScheduleHelper(
      //       this.schedule,
      //       now.valueOf()
      //     );

      //     loop_limit--;
      //   }
      // }

      
      //18:56
      //56 % 10 = 6
      // now.minutes(now.minutes() + (10 - (now.minutes() % 10)));
      let now = moment().add(30, 'minutes');
      const max_date = moment(now).add(12, "hours");
      let times = [];

      const helper = new ScheduleService.ScheduleHelper(
        this.cafe.schedule,
        now.valueOf()
      );

      const end_moment = moment(helper.endMoment);

      while (now.isBefore(end_moment)) {
        times.push(now.toDate());
        now.add(10, "minutes");
      }

      return times;
    },
  },
  methods: {
    onConfirm() {
      this.validation = {
        name: true,
        phone: true,
        pay_method: true,
        time: true,
      };

      if (!this.details.name) {
        this.validation.name = false;
      }

      if (!this.details.phone) {
        this.validation.phone = false;
      }

      // if (this.details.target_date == "none") {
      //   this.validation.time = false;
      // }

      if (!this.details.pay_method) {
        this.validation.pay_method = false;
      }

      if (
        !this.validation.name ||
        !this.validation.phone ||
        !this.validation.time ||
        !this.validation.pay_method
      ) {
        return;
      }


      this.onPhoneConfirmed();

      // if (
      //   this.$store.getters["clients/isAuthorized"] &&
      //   this.$store.state.clients.client.phone == this.details.phone
      // ) {
        
      // } else {
      //   this.askPhoneConfirmation();
      // }
    },
    checkClientCache() {
      if (this.$store.getters["clients/isAuthorized"]) {
        const client = this.$store.state.clients.client;

        this.details.name = client.name;
        this.details.phone = client.phone;
      }
    },
    onNewPhoneConfirmed(value) {
      this.details.phone = value;
    },
    askPhoneConfirmation() {
      this.confirming_phone = true;

      SecurityAPI.ask(this.details.phone)
        .then((data) => {
          this.confirm_phone_token = data.token;
        })
        .catch((error) => {
          alert(
            `Сталася помилка підтвердження. Будь ласка, спробуйте пізніше.`
          );
        });
    },
    onPhoneConfirmed() {
      this.$store
        .dispatch("clients/create", {
          name: this.details.name,
          phone: this.details.phone,
        })
        .finally(() => {
          this.confirming_phone = false;
          this.$emit("confirm", this.details);
        });
    },
  },
  mounted() {
    this.checkClientCache();
  },
};
</script>

<style scoped>
.room-order-details {
  padding: 0 2.5vh 0;
}

.order-confirm-button {
  margin: 2.5vh auto 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  border-radius: 1.5vh;
  width: 100%;
  text-align: center;
  background: #129b81 !important;
  color: #ededed !important;
  padding: 1.6vh;
}

.order-confirm-button .pay {
  color: #ededed !important;
}

.order-confirm-button h4 {
  font-size: 2vh;
  margin: 0;
  line-height: normal;
}

.comment-field {
  margin-top: 1.65vh;
}
</style>