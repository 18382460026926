<template>
  <div class="rooms-cart-screen">
    <OrderCart
      :cart="cart"
      :target="target"
      :disabled="busy"
      :number="1"
      :min_check="0"
      :cashback="0"
      :freeze="0"
      :hideSendButton="true"
      v-if="cart && cart.length > 0"
    />

    <RoomOrderDetails 
      :cafe="cafe"
      @confirm="sendOrder"
      v-if="cart && cart.length"/>

    <OrderButtons 
      :target="target" 
      :showArrow="true" 
      :mode="'cart'"
      :amount="cart.length"
      @switchView="$emit('switchView', 'menu')"/>

    <PandaFatalError
      :error="error" 
      v-if="error"/>

    <OnlinePaymentModal 
      :mode="'hotels'"
      :order_id="room_order._id"
      @close="pay_modal = false"
      @success="onConfirmed"
      v-if="pay_modal"
      />
  </div>
</template>

<script>
import OrderCart from "@/components/OrderCart.vue";

import RoomOrderDetails from "@/components/hotels/RoomOrderDetails.vue"

import PandaFatalError from "@/components/modals/PandaFatalError.vue"

import HotelsAPI from "@/api/hotels"

import OrderButtons from "@/components/OrderButtons.vue"

import OnlinePaymentModal from "@/components/modals/OnlinePaymentModal.vue"

export default {
  components: {
    OrderCart,
    RoomOrderDetails,
    PandaFatalError,
    OrderButtons,
    OnlinePaymentModal
  },
  props: {
    cafe: Object,
    target: Object
  },
  data() {
    return {
      busy: false,
      cart_comment: null,
      error: null,

      pay_modal: false,
      room_order: null,

      sending_order: false
    }
  },
  methods: {
    onConfirmed() {
      this.$emit('created', this.room_order);
      this.$store.dispatch('cart/clear');
    },
    sendOrder(user_details) {
      if (this.sending_order) {
        return;
      }

      this.sending_order = true;

      const body = {
        client_name: user_details.name,
        client_phone: user_details.phone,

        client_id: this.$store.state.clients.client._id,

        target_date: null,

        comment: user_details.comment || null,

        cafe_id: this.cafe._id,
        room: this.target.room,
        section: this.target.section,

        pay_method: user_details.pay_method,

        list: this.cart
      }

      HotelsAPI.create(body).then(data => {
        this.room_order = data.room_order;

        if (this.room_order.status == 'confirmed') {
          this.onConfirmed();
        } else {
          this.pay_modal = true;
        }

        
      }).catch(error => {
        this.error = this.$t(`errors.${error}`);
      }).finally(() => {
        this.sending_order = false;
      })
    }
  },
  computed: {
    cart() {
      return this.$store.state.cart.items;
    }
  }
};
</script>

<style scoped>
.rooms-cart-screen {
  padding-top: 9.5vh;
  margin-bottom: 15vh;
  min-height: 85vh;
}
</style>